import React from "react";
import Helmet from 'react-helmet'
import Layout from "../components/layout";

export default (props) => (
  <React.Fragment>
  <Layout locationInfo={props.location}>
    <Helmet>
      <title>Page Not Found</title>
    </Helmet>
    <section style={{padding: '50px 0', }}>
      <article className="fixed">
        <h1>Page Not Found</h1>
        <p>The page you are looking for cannot be found.</p>
      </article>
    </section>
  </Layout>
  </React.Fragment>
);
